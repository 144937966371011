"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect } from "react";

export default function Footer() {
  return (
    <footer>
      {/*Desktop Footer*/}
      <section className="hidden lg:block px-3 md:px-5 xl:px-10 mx-auto">
        <div className="flex flex-col justify-end gap-5 pb-5 h-[440px]">
          <div className="w-full flex justify-center items-center">
            <Link href="/" className="w-fit">
              <Image
                className="md:w-[170.5px] lg:w-[200px] xl:w-[240px] 2xl:w-[271.5px]"
                src="/logo.png"
                alt="logo"
                width={271.5}
                height={33}
              />
            </Link>
          </div>
          <div className="flex flex-col gap-5 w-full pt-6">
            <ul className="flex justify-center items-center gap-12 text-[20px]">
              <Link
                className="hover:text-[#FF6D25] text-[#333333] font-medium leading-[24px] transition-colors duration-300 ease-in-out"
                href="/about-us"
              >
                Бидний тухай
              </Link>
              <Link
                className="hover:text-[#FF6D25] text-[#333333] font-medium leading-[24px] transition-colors duration-300 ease-in-out"
                href="/about-us#contact"
              >
                Холбоо барих
              </Link>
            </ul>
            <div className="flex justify-center">
              <span className="flex gap-1 text-base mt-4 text-[#626972]">
                Supported by
                <p className="font-semibold text-[#333333]">
                  KLEINE BILDUNGSCHANCE e.V
                </p>
              </span>
            </div>
          </div>
          <section className="w-full lg:h-[50px] xl:h-[70px] flex items-end justify-between">
            <div className="flex justify-center">
              <span className="text-base text-[#626972] whitespace-nowrap">
                © 2024 Miniiwiki.mn. Зохиогчийн эрхийг хуулиар хамгаална.
              </span>
            </div>

            <div className="">
              <span className="flex items-center gap-1 text-base text-[#626972] whitespace-nowrap">
                Powered by
                <Link href={"https://datacom.mn/"} target="_blank">
                  <Image
                    className="w-[119px] h-[15px] flex items-center"
                    src={"/Datacom.png"}
                    width={119}
                    height={15}
                    alt={"Datacom"}
                  />
                </Link>
              </span>
            </div>
          </section>
        </div>
      </section>
      {/*Desktop Footer End*/}
      {/*Tablet Footer*/}
      <section className="hidden md:block lg:hidden px-3 md:px-5 xl:px-10 mx-auto">
        <div className="flex flex-col justify-end gap-5 pb-5 h-[440px]">
          <div className="w-full flex justify-center items-center">
            <Link href="/" className="w-fit">
              <Image
                className="md:w-[170.5px] lg:w-[271.5px]"
                src="/logo.png"
                alt="logo"
                width={271.5}
                height={33}
              />
            </Link>
          </div>
          <div className="flex flex-col gap-5 w-full pt-5">
            <ul className="flex justify-center items-center font-semibold gap-12 text-[16px]">
              <Link className="text-[#333333]" href="/about-us">
                Бидний тухай
              </Link>
              <Link className="text-[#333333]" href="/about-us#contact">
                Холбоо барих
              </Link>
            </ul>
            <div className="flex justify-center items-center w-full">
              <span className="flex gap-1 text-base text-[#616872]">
                Supported by
                <p className="font-bold text-[#333333] text-sm">
                  KLEINE BILDUNGSCHANCE e.V
                </p>
              </span>
            </div>
          </div>
          <section className="w-full md:h-[55px] lg:h-[60px] xl:h-[80px] flex items-end justify-between">
            <div className="flex justify-center">
              <span className="text-sm text-[#616872] whitespace-nowrap flex flex-col">
                © 2024 Miniiwiki.mn. <p>Зохиогчийн эрхийг хуулиар хамгаална.</p>
              </span>
            </div>

            <div className="">
              <span className="flex items-center gap-1 text-sm text-[#616872] whitespace-nowrap">
                Powered by
                <Link href={"https://datacom.mn/"} target="_blank">
                  <Image
                    className="w-[119px] h-[15px] flex items-center"
                    src={"/Datacom.png"}
                    width={119}
                    height={15}
                    alt={"Datacom"}
                  />
                </Link>
              </span>
            </div>
          </section>
        </div>
      </section>
      {/*Tablet Footer End*/}
      {/*Mobile Footer*/}
      <section className="block md:hidden h-full px-5 xl:px-10 mx-auto">
        <div className="flex flex-col justify-end pb-5 h-[300px]">
          <div className="w-full flex items-center pb-6">
            <Link href="/" className="w-fit">
              <Image
                className="hidden md:block"
                src="/logo.png"
                alt="logo"
                width={271.5}
                height={33}
              />
              <Image
                className="block md:hidden w-[112px] h-[14px]"
                src="/logo.png"
                alt="logo"
                width={170}
                height={33}
              />
            </Link>
          </div>
          <div className="flex flex-col gap-5 w-full">
            <ul className="flex flex-col gap-3 text-md font-semibold pb-[18px]">
              <Link className="text-[#333333]" href="/about-us">
                Бидний тухай
              </Link>
              <Link className="text-[#333333]" href="/about-us#contact">
                Холбоо барих
              </Link>
            </ul>
          </div>
          <section className="w-full flex flex-col gap-3 justify-between">
            <div className="flex">
              <span className="flex gap-1 text-sm text-[#616872]">
                Supported by
                <p className="font-bold text-[#333333]">
                  KLEINE BILDUNGSCHANCE e.V
                </p>
              </span>
            </div>
            <div className="">
              <span className="flex items-center gap-1 text-sm text-[#616872]">
                Powered by
                <Link href={"https://datacom.mn/"} target="_blank">
                  <Image
                    className="w-[90px] h-[12px] flex items-center"
                    src={"/Datacom.png"}
                    width={119}
                    height={15}
                    alt={"Datacom"}
                  />
                </Link>
              </span>
            </div>
          </section>
          <div className="flex justify-center pt-6">
            <span className="text-[13.3px] text-[#616872]">
              © 2024 Miniiwiki.mn. Зохиогчийн эрхийг хуулиар хамгаална.
            </span>
          </div>
        </div>
      </section>
      {/*Mobile Footer End*/}
    </footer>
  );
}
